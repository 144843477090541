import { sempreMessage } from '../../shared/sempre-message.const';
import { PortalService } from '../portal.service';
import { UtilService } from '../../services/util.service';
import { DialogService } from '../../services/dialog.service';
import { ApiService } from '../../services/api.service';
import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-profile-user',
    templateUrl: './profile-user.page.html',
    styles: [ `
    ion-toolbar {
        --background-color: transparent;
    }
    ion-item {
        cursor: pointer;
    }
    ion-item-divider {
        min-height: 1px !important
    }
    
    span.action-sheet-button-inner.sc-ion-action-sheet-ios  {
        work-break: break-work !important;
    }
    `]
})

export class ProfileUserPage implements OnInit, AfterViewInit {

    screen: 'CHANGE' | 'RECOVER' | 'NONE' = 'NONE' 
    TYPE_RECOVER: 'TELEFONE' | 'EMAIL'; 
    RECOVER_OPTIONS = [];
    form: FormGroup;
    showPassword: boolean = false;
    selectedType;
    @ViewChild('header', {read: ElementRef}) header: ElementRef;
    @ViewChild(IonContent, {read: IonContent, static: false}) myContent: IonContent;
    openHeight = 0;


    constructor(
        public apiService: ApiService,
        private dialogService: DialogService,
        private utilService: UtilService,
        private fb: FormBuilder,
        private portalService: PortalService,
    ) { 
        this.generateForm()
    }

    async ngAfterViewInit() {

    }

    async ngOnInit() {

    }



    close(){
        this.dialogService.modalCtrl.dismiss()
    }

    exitPlatform(){
        this.close();
        this.apiService.doLogout()
    }

     generateForm(){
        this.form = this.fb.group({
            currentPassword: [null, Validators.required],
            newPassword: [null, [Validators.required, Validators.minLength(6)]],
            confirmedPassword: [null, [Validators.required, Validators.minLength(6)]],
        })
    }


    async setChangeRadio(event){
        console.log(event)
        this.TYPE_RECOVER = event.detail.value;
        const keyValue = Object.entries(this.apiService.user)
        this.RECOVER_OPTIONS = [];

       await keyValue.forEach( (value) => {
            const match = value[0].toUpperCase().includes(this.TYPE_RECOVER)
            if(match && value[1] && this.RECOVER_OPTIONS.findIndex( item => item.origem === value[1]) < 0){
                this.RECOVER_OPTIONS.push({ 
                    mask: this.utilService.changePerAsterisk(value[1], this.TYPE_RECOVER),
                    origem: value[1]
                })
            }
    
        })

        setTimeout( () => this.myContent.scrollToBottom(200),200 )        
    }

    recoverPassword(event, type) {
        console.log(event, type)
        this.portalService.recoverPassword({
            cpfCnpj: environment.API_PORTAL.CLIENTE,
            empresa: environment.API_PORTAL.EMPRESA,
            type,
            value: event.origem
        })
        .then(_=> this.dialogService.showMessage(_.mensagem))
        .catch(_=> this.utilService.handleErrorHttp(_))
    }

    async submitForm(){
        const form = this.form;
        if(form.value.newPassword === form.value.currentPassword){
            return this.dialogService.showMessage('A senha nova não pode ser igual a senha atual', sempreMessage.COLOR_WARNING)
        }
        if(form.value.newPassword !==  form.value.confirmedPassword){
            return this.dialogService.showMessage('A senha atual está diferente da confirmação', sempreMessage.COLOR_WARNING)
        }


        const isApproved = await this.utilService.isValidForm(form);
        if(isApproved){
            this.portalService.changePassword({
                cpfCnpj: environment.API_PORTAL.CLIENTE,
                senhaAtual: form.value.currentPassword,
                senhaNova: form.value.newPassword
            })
            .then(_=> {  form.reset(); this.dialogService.showMessage(_.mensagem) })
            .catch(_=> this.utilService.handleErrorHttp(_))
        }
    }

    clickEventHandler(e){
        console.log(e)
    }



  
}