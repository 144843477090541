import { SempreFooterComponent } from './../components/footer/sempre-footer.component';
import { ButtonSwiperModalComponent } from './../components/swiper/button-swiper-modal.component';
import { RegisterUserPage } from './../portal/user/register/register-pwd.page';
import {  NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgPipesModule } from 'ngx-pipes';
import { SempreHeaderComponent } from '../components/header/sempre-header.component';
import { AvatarModule } from 'ngx-avatar';
import { ProfileUserPage } from '../portal/user/profile-user.page';
import { MessageErrorInputComponent } from '../components/input/message-error-input.component';

const MODULES = [
  CommonModule,
  IonicModule,
  FormsModule,
  ReactiveFormsModule,
  AvatarModule,
  NgPipesModule,
  FlexLayoutModule,
];

@NgModule({
  declarations: [
    SempreHeaderComponent,
    SempreFooterComponent,
    ProfileUserPage,
    RegisterUserPage,
    MessageErrorInputComponent,
    ButtonSwiperModalComponent,
  ],
  imports: [MODULES],
  exports: [
    SempreHeaderComponent,
    SempreFooterComponent,
    ProfileUserPage,
    RegisterUserPage,
    MessageErrorInputComponent,
    ButtonSwiperModalComponent,
    MODULES,
  ],
})
export class SharedModule {}
