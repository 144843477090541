// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  BASIC_AUTH_TOKEN: 'Basic c2VtcHJlYXBpOlMzbXByM0BBcDE=',
  API_LOGIN: {
    USERNAME: 'portalsva',
    PASSWORD: 'portal@sva',
    GRANT_TYPE: 'password'
  },
  API_EDUCA: {
    URL: 'https://ws.sempre.net.br/educa-hmg',
    STATE_TOKEN: {
      CREDENTIAL: '',
    },
    NUBBI: {
      INPUT_LOGIN: '',
      INPUT_PWD: '',
      URL_AUTH: ''
    }
  },
  API_PORTAL: {
    URL: 'https://ws.sempre.net.br/api-hmg',
    JWT: '',
    STATE_TOKEN: {
      CREDENTIAL: '',
      expires_in: null
    },
    LINK_CENTRAL: '',
    EMPRESA: '',
    CLIENTE: '',
    USUARIO: ''
  },

  // API_HUBSOFT: {
  //   URL: 'https://api.sempre.hubsoft.com.br/oauth/token'
  //   access_token: '',
  // }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
