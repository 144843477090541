import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-sempre-footer",
  templateUrl: "sempre-footer.component.html",
  styleUrls: ["./sempre-footer.component.scss"],
})
export class SempreFooterComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  openPage(url) {
    window.open(url, "_blank");
  }
}
