import { Location } from "@angular/common";

import { environment } from "src/environments/environment";
import { ApiService } from "./../services/api.service";
import { PortalService } from "./../portal/portal.service";
import { Injectable, NgZone } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  Router,
} from "@angular/router";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {

  constructor(
    private portalService: PortalService,
    private apiService: ApiService
    ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {


    if (state.url.split('?')[0] === '/' && route.queryParams.cliente && route.queryParams.empresa && route.queryParams.token) {
      environment.API_PORTAL.CLIENTE = route.queryParams.cliente.replace(/[^0-9]/g, ""); // somente numero
      environment.API_PORTAL.EMPRESA = route.queryParams.empresa;
      environment.API_PORTAL.JWT = route.queryParams.token;
      await this.apiService.saveCredential();
    }

    

    return this.portalService.checkSessionActive();
  }



}
