import { Injectable } from '@angular/core';
import { LoadingController, ToastController, AlertController, ActionSheetController, ModalController, PopoverController, GestureController, AnimationController,  } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    public actionSheet: ActionSheetController,
    public toastCtrl: ToastController,
    public modalCtrl: ModalController,
    public popoverCtrl: PopoverController,
    public gestureCtrl: GestureController,
    public animationCtrl: AnimationController
  ) { }

  async showAlert(message, subHeader = '', header = 'Aviso', buttons = [{text: 'OK'}] ) {
    const alert = await this.alertCtrl.create({
      header,
      subHeader,
      message,
      buttons
    });

    await alert.present();
    return alert;
  }

  async showLoading(message = 'Carregando. aguarde...', duration = 0) {
    const loading = await this.loadingCtrl.create({
      spinner: 'crescent',
      duration,
      message,
      translucent: true,
      cssClass: 'sempre-loading'
    });
    loading.present();
    return loading;
  }

  async showMessage(message: string, color: string = 'sempre-purple', duration = message.length  * 200) {
    if (isNaN(duration)) {
      duration = 5000;
    }
    const toast = await this.toastCtrl.create({
        message,
        duration,
        color,
        position: 'top',
        buttons: [ { text: 'X'}]
    });
    toast.present();
    return toast;
  }
}
