import { Platform } from '@ionic/angular';
import { DialogService } from './../../services/dialog.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-button-swiper-modal',
    template: `
            <button class="swiperBar" *ngIf="this.plt.is('mobile')">

            </button>
    `,
    styles: [`

        .swiperBar {
            content: '';
            height: 5px;
            width: 75px;
            border-radius: 100px;
            left: 50%;
            transform: translateX(-50%);
            position: relative;
            top: 3px;
            z-index: 300000;
            outline: none
        }
    `]

})

export class ButtonSwiperModalComponent implements OnInit {

    private animation?;
    private gesture?;
    @Input('header') header = 116 
    private started: boolean = false;
    private MAX_TRANSLATE: number = this.plt.height() - (150 + this.header) ;
    private initialStep: number = 0.5;
    private stepCurrent: number =  0 
    modal;

    constructor(
        private dialogService: DialogService,
        public plt: Platform
    ) { }

    async ngOnInit() {
        this.enableSwiperMobile();
     }

     async enableSwiperMobile(){

     
        if(this.plt.is('mobile')){

            this.modal = (await this.dialogService.modalCtrl.getTop()).getElementsByClassName('modal-wrapper')[0]
    
            this.animation = this.dialogService.animationCtrl.create()
            .addElement(this.modal)
            .duration(1000)
            .fromTo('transform', `translateY(-150px)`, `translateY(${this.MAX_TRANSLATE}px)`);
        
          this.gesture = this.dialogService.gestureCtrl.create({
            el: this.modal,
            gestureName: 'swiper',
            direction: 'y',
            onMove:  ev  => this.onMove(ev),
            onEnd:  ev   => this.onEnd(ev)
          })
        
          this.gesture.enable(true);
        }
     }

     
     private onMove(ev) {
       if (!this.started) {
         this.animation.progressStart(true);
         this.started = true;
       }
       this.stepCurrent = this.getStep(ev)
       if(this.stepCurrent === 0){
        this.modal['style']['border-radius'] = '0'
        }else{
            this.modal['style']['border-radius'] = '20px 20px 2px 0px'
        }
       this.animation.progressStep(this.stepCurrent);
     }
     
      private onEnd(ev) {
       if (!this.started) { return; }
           
       this.stepCurrent = this.getStep(ev);
       const shouldComplete = this.stepCurrent  > 0.5;


       this.initialStep = (shouldComplete) ? this.MAX_TRANSLATE : 0;
       this.started = false;
   }
     
   private clamp(min, n, max) {
       const camp = Math.max(min, Math.min(n, max))
       return camp;
   }
     
   private getStep(ev) {
       const delta = this.initialStep + ev.deltaY;
       return this.clamp(0, delta / this.MAX_TRANSLATE, 1);
   }
}