import { Storage } from "@ionic/storage";
import { ApiService } from "../services/api.service";
import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import * as moment from "moment";
import { delay } from "rxjs/operators";

type recoverPassword = {
  cpfCnpj: string;
  empresa: string;
  type: "EMAIL" | "TELEFONE";
  value: string;
};

type changePassword = {
  cpfCnpj: string;
  senhaNova: string;
  senhaAtual: string;
};

type createPassword = {
  cpfCnpj: string;
  empresa: string;
  senha: string;
};

type credentialService = {
  urlProducao: string;
  urlHomologacao: string;
  tokenServico: string;
};

@Injectable({ providedIn: "root" })
export class PortalService {
  service = [];
  user;

  constructor(private apiService: ApiService, private storage: Storage, private router: Router) {}

  private async checkAuthCenter(cpfCnpj: string, empresa: string, token: string) {
    // Verifica-se o cliente autenticou na central do provedor
    const URL = `${environment.API_PORTAL.URL}/integracao/cliente/autorizar`;
    const BODY = {
      cpfCnpj,
      empresa,
      token,
    };
    const OPTIONS = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: await this.getTokenPortalService(),
      }),
    };

    return this.apiService.POST(URL, BODY, OPTIONS).toPromise();
  }

  async getServiceAvailableClientEduca(cpfCnpj: string, empresa: string) {
    const URL = `${environment.API_PORTAL.URL}/integracao/servico/educa?cliente=${cpfCnpj}&empresa=${empresa}`;
    const OPTIONS = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: await this.getTokenPortalService(),
      }),
    };

    return this.apiService.GET(URL, OPTIONS).toPromise();
  }

  async getAllServiceProviderCenter(cpfCnpj: string, empresa: string) {
    const URL = `${environment.API_PORTAL.URL}/integracao/cliente/servicos?cliente=${cpfCnpj}&empresa=${empresa}`;
    const OPTIONS = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: await this.getTokenPortalService(),
      }),
    };

    return this.apiService.GET(URL, OPTIONS).toPromise();
  }

  async recoverPassword({ ...arg }: recoverPassword) {
    const type = arg.type === "EMAIL" ? "email" : "sms";
    const URL = `${environment.API_PORTAL.URL}/integracao/cliente/senha/recuperar-${type}`;
    const BODY = {
      cpfCnpj: arg.cpfCnpj,
      empresa: arg.empresa,
      [arg.type.toLowerCase()]: arg.value,
    };
    const OPTIONS = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: await this.getTokenPortalService(),
      }),
    };
    return this.apiService.POST(URL, BODY, OPTIONS).toPromise();
  }

  async changePassword({ ...arg }: changePassword) {
    const URL = `${environment.API_PORTAL.URL}/integracao/cliente/senha/atualizar`;
    const BODY: changePassword = {
      ...arg,
    };
    const OPTIONS = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: await this.getTokenPortalService(),
      }),
    };
    return this.apiService.POST(URL, BODY, OPTIONS).toPromise();
  }

  async checkCredentialPortalService(cpfCnpj: string) {
    const URL = `${environment.API_PORTAL.URL}/integracao/cliente/senha/consulta/${cpfCnpj}`;
    const OPTIONS = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: await this.getTokenPortalService(),
      }),
    };
    return this.apiService.GET(URL, OPTIONS).toPromise();
  }

  async createPasswordPortal({ ...arg }: createPassword) {
    const URL = `${environment.API_PORTAL.URL}/integracao/cliente/senha/nova`;
    const BODY = {
      ...arg,
    };
    const OPTIONS = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: await this.getTokenPortalService(),
      }),
    };
    return this.apiService.POST(URL, BODY, OPTIONS).toPromise();
  }

  async checkSessionActive(): Promise<any> {
    const envString = await this.apiService.storage.get("env");
    const env = JSON.parse(envString);
    console.log(env);

    if (env && env.API_PORTAL.CLIENTE) {
      environment.API_PORTAL = env.API_PORTAL;
      environment.API_EDUCA = env.API_EDUCA;
      environment.API_LOGIN = env.API_LOGIN;
      this.apiService.user = environment.API_PORTAL.USUARIO;

      if (!environment.API_PORTAL.LINK_CENTRAL) {
        this.getURLCenter(environment.API_PORTAL.EMPRESA).then(async (link) => {
          environment.API_PORTAL.LINK_CENTRAL = link.central;
          await this.apiService.saveCredential();
        });
      }
      return this.checkAuthCenter(environment.API_PORTAL.CLIENTE, environment.API_PORTAL.EMPRESA, environment.API_PORTAL.JWT)
        .then((response) => {
          if (!response.autorizado) {
            this.router.navigateByUrl("/status/401");
          }

          return response.autorizado;
        })
        .catch((_) => {
          this.router.navigateByUrl("/status/401");
          return false;
        })
        .finally(() => {
          this.apiService.isLoading = false;
        });
    } else {
      this.router.navigateByUrl("/status/401");
      this.apiService.isLoading = false;
      return false;
    }
  }

  async getTokenPortalService() {
    const tokenTime = environment.API_PORTAL.STATE_TOKEN.expires_in
      ? moment(environment.API_PORTAL.STATE_TOKEN.expires_in).diff(moment().toDate(), "minutes")
      : 0;

    if (tokenTime <= 5) {
      const response = await this.apiService.authAnonymously().toPromise();

      const time = moment().add(response.expires_in, "seconds").toDate();
      const token = `${response.token_type} ${response.access_token}`;
      environment.API_PORTAL.STATE_TOKEN = response;
      environment.API_PORTAL.STATE_TOKEN.expires_in = time;
      environment.API_PORTAL.STATE_TOKEN.CREDENTIAL = token;
    }

    console.log(tokenTime, environment.API_PORTAL.STATE_TOKEN.expires_in, moment().toDate());
    return await environment.API_PORTAL.STATE_TOKEN.CREDENTIAL;
  }

  async updatesUserData() {
    await this.getClientProviderCenter(environment.API_PORTAL.CLIENTE, environment.API_PORTAL.EMPRESA).then(async (result) => {
      result.firstName = result.nome.split(" ")[0];
      environment.API_PORTAL.USUARIO = result;
      this.apiService.user = result;
      await this.apiService.saveCredential();
    });
  }

  async activeServiceClient(cpfCnpj, ServiceName, credential: credentialService) {
    let URL = credential.urlHomologacao;
    if (environment.production) {
      URL = credential.urlProducao;
    }
    const URL_WITHOUT_PARAMS = `${URL}/integracao/servico/ativar`;
    const BODY = {
      cliente: cpfCnpj,
      servico: ServiceName,
    };
    const OPTIONS = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: credential.tokenServico,
      }),
    };
    return this.apiService.POST(URL_WITHOUT_PARAMS, BODY, OPTIONS).toPromise();
  }

  async checkServiceClientActivated(cpfCnpj, nameService, credential: credentialService) {
    let URL = credential.urlHomologacao;
    if (environment.production) {
      URL = credential.urlProducao;
    }

    const URL_WITH_PARAMS = `${URL}/integracao/servico/pesquisar?cliente=${cpfCnpj}&servico=${nameService}`;
    const OPTIONS = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: await credential.tokenServico,
      }),
    };
    return this.apiService.GET(URL_WITH_PARAMS, OPTIONS).toPromise();
  }

  async getClientProviderCenter(cpfCnpj: string, empresa: string) {
    // Responsavel por pegar dados cadastral na central/base de dados do provedor
    console.log(environment.API_PORTAL);

    const URL = `${environment.API_PORTAL.URL}/integracao/cliente/dados?cliente=${cpfCnpj}&empresa=${empresa}`;
    const OPTIONS = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: await this.getTokenPortalService(),
      }),
    };
    return this.apiService.GET(URL, OPTIONS).toPromise();
  }

  async getURLCenter(empresa: string) {
    // Responsavel por obter a url da central

    const URL = `${environment.API_PORTAL.URL}/integracao/cliente/central?empresa=${empresa}`;
    const OPTIONS = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: await this.getTokenPortalService(),
      }),
    };
    return this.apiService.GET(URL, OPTIONS).toPromise();
  }

  async getConfigService(empresa: string, servico: string) {
    // Responsavel por obter configurações do serviço

    const URL = `${environment.API_PORTAL.URL}/integracao/servico/config?empresa=${empresa}&servico=${servico}`;
    const OPTIONS = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: await this.getTokenPortalService(),
      }),
    };
    return this.apiService.GET(URL, OPTIONS).toPromise();
  }

  async getCentral() {
    // Responsavel por obter configurações do serviço

    const URL = `${environment.API_PORTAL.URL}/integracao/centrais`;
    const OPTIONS = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: await this.getTokenPortalService(),
      }),
    };
    return this.apiService.GET(URL, OPTIONS).toPromise();
  }
}
