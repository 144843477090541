import { Router } from "@angular/router";
import { Storage } from "@ionic/storage";
import { environment } from "./../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { timeout } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ApiService {
  public LINK_CENTRAL;
  public user;
  public isLoading = true;

  constructor(private http: HttpClient, public storage: Storage, private router: Router) {}

  GET(url: string, options: any, timeOut = 20000): Observable<any> {
    return this.http.get(url, options).pipe(timeout(timeOut));
  }

  POST(url, body: any, options: any, timeOut = 20000): Observable<any> {
    return this.http.post(url, body, options).pipe(timeout(timeOut));
  }

  PUT(url: string, body, options: any, timeOut = 20000): Observable<any> {
    return this.http.put(url, body, options).pipe(timeout(timeOut));
  }

  DELETE(url: string, options: any, timeOut = 20000): Observable<any> {
    return this.http.delete(url, options).pipe(timeout(timeOut));
  }

  getOption(){
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: environment.BASIC_AUTH_TOKEN,
      }),
    };
  }

  authAnonymously(): Observable<any> {
    // Responsavel por autenticar no portal anonimamente para segurança do front e backend

    const headers = new HttpHeaders().append("Content-Type", "application/x-www-form-urlencoded").append("Authorization", `${environment.BASIC_AUTH_TOKEN}`);
    const body = `username=${environment.API_LOGIN.USERNAME}&password=${environment.API_LOGIN.PASSWORD}&grant_type=${environment.API_LOGIN.GRANT_TYPE}`;

    return this.http.post<any>(`${environment.API_PORTAL.URL}/oauth/token`, body, { headers, withCredentials: true });
  }

  doLogout() {
    console.log(this.LINK_CENTRAL);

    this.storage.clear().then(() => {
      environment.API_PORTAL.EMPRESA = "";
      environment.API_PORTAL.CLIENTE = "";
      environment.API_PORTAL.JWT = "";
      if (this.LINK_CENTRAL) {
        window.location.href = this.LINK_CENTRAL;
      } else {
        this.router.navigateByUrl("/status/401");
      }
    });
  }

  saveCredential() {
    return this.storage.set("env", JSON.stringify(environment));
  }
}
