import { ProfileUserPage } from '../../portal/user/profile-user.page';
import { DialogService } from './../../services/dialog.service';
import { environment } from './../../../environments/environment';
import { Platform } from '@ionic/angular';
import { ApiService } from './../../services/api.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-sempre-header',
    template: `
  
    
        <a href="/">
            <img [src]="this.src" class="image-logo" [ngClass]="this.plt.width() < 380 ? 'mobile' : 'other'" />
        </a>

        <div *ngIf="showAvatar" class="avatar" (click)="openMenuProfile()" 
        [style.top]="this.plt.width() < 380 ? '50px' : '70px'">

            <ngx-avatar 
            initialsSize="1"
            [style]="{cursor: 'pointer'}" size="35" [round]="true" [name]="this.apiService.user?.nome" bgColor="#5A58A4" textSizeRatio=2>
            </ngx-avatar>
            <div>

                <ion-text color="primary" style="text-transform: capitalize">{{this.apiService.user?.firstName | lowercase}}</ion-text>
                <ion-icon name="caret-down-outline" color="primary"></ion-icon>
            </div>
        </div>



        <!-- <ion-button color="sempre-purple" *ngIf="this.apiService.LINK_CENTRAL"  (click)="this.apiService.doLogout()" fill="clear" >
            Sair
            <ion-icon name="exit" slot="end" size="large"></ion-icon>
        </ion-button> -->
    
    `,
    styleUrls: ['./sempre-header.component.scss'],
})

export class SempreHeaderComponent implements OnInit {

    @Input() src;
    @Input() showAvatar = true;

    constructor(
        public apiService: ApiService,
        public plt: Platform,
        private dialog: DialogService
    ) { }

    ngOnInit() {
        console.log(environment)
    }

    async openMenuProfile(){
       const popover = await this.dialog.modalCtrl.create({
            component: ProfileUserPage,
            backdropDismiss: false,
            cssClass: 'modal-profile-user',
        })

        return await popover.present();
    }


}