import { AuthGuard } from './shared/auth.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./portal/portal.module').then((m) => m.PortalPageModule),
  },
  {
    path: 'sempre-educa',
    loadChildren: () => import('./page/sempre-educa/sempre-educa.module').then((m) => m.SempreEducaPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'telefonia',
    loadChildren: () => import('./page/telephony/telephony.module').then((m) => m.TelephonyPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'vendas',
    loadChildren: () => import('./page/sales/sales.module').then((m) => m.SalesPageModule),
  },
  { path: '', redirectTo: '', pathMatch: 'full' },
  {
    path: 'status/:id',
    loadChildren: () => import('./notice-board/notice-board.module').then((m) => m.NoticeBoardPageModule),
  },
  {
    path: '**',
    redirectTo: '/status/404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
