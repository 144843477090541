import { PortalService } from "./portal/portal.service";
import { ApiService } from "./services/api.service";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { Component } from "@angular/core";

import { Platform } from "@ionic/angular";
import { StatusBar } from "@ionic-native/status-bar/ngx";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  isOpenKeyBoard = false;

  constructor(private platform: Platform, private statusBar: StatusBar, public apiService: ApiService) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.overlaysWebView(this.platform.is("ios") ? false : true);

      const modeDark = false;
      this.statusBar.backgroundColorByHexString(modeDark ? "#5A58A4" : "#5A58A4");
      this.statusBar.styleLightContent();
      document.body.classList.toggle("dark", modeDark);
      this.handleKeyBoard();
      // Quando a rota  raiz, carregando será desativado checar autenticação, se for superior 1500ms, esse setTimeout vai remover o carregando
      setTimeout(() => {
        this.apiService.isLoading = false;
      }, 1500);
    });
  }

  handleKeyBoard() {
    this.platform.keyboardDidShow.subscribe((ev) => {
      const { keyboardHeight } = ev;
      const modal = document.querySelector(".modal-profile-user .modal-wrapper");

      if (keyboardHeight > 0) {
        this.isOpenKeyBoard = !this.isOpenKeyBoard;
      }
      console.log(this.isOpenKeyBoard, modal["style"].marginTop);
      if (this.isOpenKeyBoard) {
        modal["style"].top = "0px !important";
      } else {
        modal["style"].top = "80% !important";
      }
    });
  }
}
