import { FormControl } from '@angular/forms';
import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-message-error-input',
    template: `
    <ng-container *ngIf="this.control.dirty || this.control.touched">
       <span class="message-error-input" *ngIf="this.control.hasError('minlength')">
        No mínimo {{this.control.errors.minlength.requiredLength }}.
        Atualmente tem {{this.control.errors.minlength.actualLength }}
        </span>
        <span class="message-error-input" *ngIf="this.control.hasError('required')">
            É obrigatório
        </span>
        <span class="message-error-input" *ngIf="this.control.hasError('maxlength')">
        No máximo {{this.control.errors.maxlength.requiredLength }}.
        Atualmente tem {{this.control.errors.maxlength.actualLength }}
        </span>
    </ng-container>

    `,
    styles: [`
            .message-error-input {
                color: var(--ion-color-danger);
                font-size: 13px;
                padding: 5px;
            }
    `],
})

export class MessageErrorInputComponent {
    @Input('control') control: FormControl
    ngOnInit() { }
}