import { Router } from '@angular/router';
import { ApiService } from './../api.service';
import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root'})

export class Interceptor implements HttpInterceptor {

  constructor(
    private router: Router
  ){

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(

        catchError((error) => {
          console.log(error, 'catch error intercept')
          if(error.status === 0 || error.name === 'TimeoutError' || error.status > 500 && error.status < 600){
            this.router.navigateByUrl('/status/503')
          }else {
            if(error.status === 401){
              console.log('401 não autorizado', error)
              if(error.error === 'invalid_token'){
                console.log('401 não autorizado token')
              }else{
                this.router.navigateByUrl('/status/401')
              }
            }
          }
          return throwError(error);
        }
      )
      )

  }

}
