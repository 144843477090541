import { sempreMessage } from './../shared/sempre-message.const';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DialogService } from './dialog.service';
import { throwError } from 'rxjs';

@Injectable({providedIn: 'root'})
export class UtilService {


    constructor(
        private dialogService: DialogService,
    ) { }

    handleWithFormInvalid(form: FormGroup){

        //form é pegar os campos, e falar que estão invalido.
        //depois trato isso
        this.dialogService.showMessage('Formulário invalido', sempreMessage.COLOR_ERROR)
    }

    async handleErrorHttp(err){
        if(err.name === 'TimeoutError' || err.name === 'Unknown Error'){
            err.message = 'Ocorreu um problema no servidor';
        }
    
        if(err.status === 401){
            err.message = err.error.message;
        }

        if(err.error?.length > 0){
            err.message = err.error[0].mensagemUsuario.toString();
        }
        await this.dialogService.showMessage(err.message, sempreMessage.COLOR_ERROR)
        return throwError(err.message);
    }
    
    async isValidForm(form: FormGroup): Promise<Boolean> {
        await Object.values(form.controls).forEach( (control) => {
         control.markAsDirty();
         control.updateValueAndValidity();
        })
        if(form.invalid){
          this.handleWithFormInvalid(form)
        }
        return form.valid
    }


    changePerAsterisk(string, type){

        if(type === 'TELEFONE'){
            const stringMask = this.getMaskPhoneBR(string.replace(/\D/g, ""));
            return stringMask.substr(0,8) + new Array(2).fill('*').join('') + "-" +  new Array(2).fill('*').join('') + stringMask.substr(13,15);
        }else if (type === 'EMAIL'){
            const split = string.split('@')
            return string.substr(0,3) + new Array(split[0].length - 3).fill('*').join('') + "@" + split[1];
        }

    }


    getMaskPhoneBR(string) {
        let onlyNumber = string.replace(/\D/g, "");
        onlyNumber = onlyNumber.replace(/^0/, "");
        if (onlyNumber.length > 10) {
            onlyNumber = onlyNumber.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
        } else if (onlyNumber.length > 5) {
            onlyNumber = onlyNumber.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
        } else if (onlyNumber.length > 2) {
            onlyNumber = onlyNumber.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
        } else {
            onlyNumber = onlyNumber.replace(/^(\d*)/, "($1");
        }
        return onlyNumber;
      }

      formatarMoedaBrasileira(valor: number){
        return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
      }
    
}