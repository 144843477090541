import { environment } from './../../../../environments/environment';
import { PortalService } from './../../portal.service';
import { sempreMessage } from './../../../shared/sempre-message.const';
import { UtilService } from './../../../services/util.service';
import { ApiService } from './../../../services/api.service';
import { DialogService } from './../../../services/dialog.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'app-register-user',
    templateUrl: './register-pwd.page.html',
    styles: [`
    ion-item-divider {
        min-height: 1px !important
    }
    
    `]
})

export class RegisterUserPage implements OnInit {

    form: FormGroup;
    showPassword: boolean = false;
    
    constructor(
        public apiService: ApiService,
        private dialogService: DialogService,
        private fb: FormBuilder,
        private utilService: UtilService,
        private portalService: PortalService
    ) {
        this.generateForm()
     }

    ngOnInit() { }

    close(){
        this.dialogService.modalCtrl.dismiss()
    }
    exitPlatform(){
        this.close();
        this.apiService.doLogout()
    }

    generateForm(){
        this.form = this.fb.group({
            password: [null, [Validators.required, Validators.minLength(6)]],
            confirmedPassword: [null, [Validators.required, Validators.minLength(6)]]
        })
    }
    
    async submitForm(){
        const form = this.form;
        if(form.value.password !==  form.value.confirmedPassword){
            return this.dialogService.showMessage('A senha atual está diferente da confirmação', sempreMessage.COLOR_WARNING)
        }


        const isApproved = await this.utilService.isValidForm(form);
        if(isApproved){
            this.portalService.createPasswordPortal({
                cpfCnpj: environment.API_PORTAL.CLIENTE,
                empresa: environment.API_PORTAL.EMPRESA,
                senha: form.value.password,
            })
            .then(response => { 
                this.dialogService.showMessage(response.mensagem)
                if(response.status){
                    this.dialogService.modalCtrl.dismiss({ isCreated: response.status })
                }               
            })
            .catch(_=> this.utilService.handleErrorHttp(_))
        }
    }
}