import { AuthGuard } from './shared/auth.guard';

import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { InterceptorModule } from './services/interceptor/interceptor.module';
import { AvatarModule } from 'ngx-avatar';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { environment } from '../environments/environment';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import { ServiceWorkerModule } from '@angular/service-worker';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { CalendarModule } from 'ion2-calendar';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';


registerLocaleData(localePt, 'pt');
moment.locale('pt-br');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      animated: false,
      scrollAssist: true,
      scrollPadding: false,
    }),
    IonicStorageModule.forRoot(),
    DragDropModule,
    HttpClientModule,
    AvatarModule.forRoot(),
    CalendarModule.forRoot({
      doneLabel: 'Save',
      closeIcon: true,
      monthFormat: 'MM/YYYY',
    }),
    SharedModule,
    NgxDatatableModule.forRoot({
      messages: {
        emptyMessage: 'Listagem vazia',
        selectedMessage: 'Selecionado',
        totalMessage: 'total',
      },
    }),
    InterceptorModule,
    AppRoutingModule,
    ServiceWorkerModule.register('/ngsw-config.json', { enabled: environment.production }),
  ],
  providers: [StatusBar, SplashScreen, AuthGuard, { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, { provide: LOCALE_ID, useValue: 'pt' }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
